<template>
  <top-agent-self-stop v-if="this.$store.getters.userRole === 'top_agent'" />
  <agency-self-stop v-else-if="this.$store.getters.userRole === 'agency'" />
  <merchant-self-stop v-else-if="this.$store.getters.userRole === 'merchant'" />
</template>

<script>

export default {
  name: 'ShowStop',
  components: {
    TopAgentSelfStop: () => import('@/views/agent_monthly_bills/stop/top_agent_self/show/index'),
    AgencySelfStop: () => import('@/views/agent_monthly_bills/stop/agency_self/show/index'),
    MerchantSelfStop: () => import('@/views/agent_monthly_bills/stop/agency_self/show/index')
  }
}
</script>
